<template>
  <!--Contact Form Area Start-->
  <div class="contact-form-area section-padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-12">
          <h4 class="contact-title">{{ ContactNum }}</h4>
          <div class="contact-text">
            <p>
              <span class="c-icon"><i class="fa fa-phone"></i></span
              ><span class="c-text">{{ f1 }}</span>
            </p>
            <p>
              <span class="c-icon"><i class="fa fa-phone"></i></span
              ><span class="c-text">{{ f2 }}</span>
            </p>
            <p>
              <span class="c-icon"><i class="fa fa-phone"></i></span
              ><span class="c-text">{{ f3 }}</span>
            </p>
            <p>
              <span class="c-icon"><i class="fa fa-envelope"></i></span
              ><span class="c-text">{{ f4 }}</span>
            </p>
            <p>
              <span class="c-icon"><i class="fa fa-globe"></i></span
              ><span class="c-text">{{ f5 }}</span>
            </p>
            <p>
              <span class="c-icon"><i class="fa fa-map-pin"></i></span
              ><span class="c-text">{{ f6 }}</span>
            </p>
          </div>
          <h4 class="contact-title">{{ SocialMedia }}</h4>
          <div class="link-social">
            <a target="_blank" :href="facebook"
              ><img src="/img/facebook.svg" width="32" height="32" alt=""
            /></a>
            <a :href="instgram" target="_blank"
              ><img src="/img/instagram.svg" width="32" height="32" alt=""
            /></a>
            <a :href="twitter" target="_blank"
              ><img src="/img/twitter.svg" width="32" height="32" alt=""
            /></a>
            <a :href="youtube" target="_blank"
              ><img src="/img/youtube.svg" width="32" height="32" alt=""
            /></a>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div id="ttm-contactform" class="ttm-contactform wrap-form clearfix">
            <iframe width="100%" height="600" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3846.78018819638!2d44.16452058583862!3d15.388386361411863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1603db9f91413767%3A0xb88e745c4096281e!2z2KzYp9mF2LnYqSDYp9mE2YrZhdmGINmI2KfZhNiu2YTZitisINmE2YTYudmE2YjZhSDZiCDYp9mE2KrZg9mG2YjZhNmI2KzZitin!5e0!3m2!1sar!2s!4v1667388769138!5m2!1sar!2s" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  data() {
    return {
      f1: "",
      f2: "",
      f3: "",
      f4: "",
      f5: "",
      f6: "",

      facebook: "",
      instgram: "",
      linkedIn: "",
      youtube: "",

      //translate
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      ContactNum: "",
      SocialMedia: "",
      SendTitle: "",
      sendMessage: "",
    };
  },
  methods: {
    getApis() {
      var self = this;
      var bodyFormData2 = new FormData();
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
      } else {
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);

        self.translate = self.EnTranslat;
      }
      self.ContactNum = self.translate[0]["contactUs"]["ContactNum"];
      self.SocialMedia = self.translate[0]["contactUs"]["SocialMedia"];
      self.SendTitle = self.translate[0]["contactUs"]["SendTitle"];
      self.sendMessage = self.translate[0]["contactUs"]["sendMessage"];

      bodyFormData2.append("check", "getPostDetails");
      bodyFormData2.append("Lang", localStorage.getItem("lang"));
      bodyFormData2.append("FbrnId", "");
      bodyFormData2.append("Type", "Univ.Footer.Data");
      bodyFormData2.append("ParentId", "NTROOT0");
      bodyFormData2.append("PostName", "");
      bodyFormData2.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData2,
      })
        .then(function (response) {
          self.FooterData = response.data;
          if (self.FooterData.length != 0) {
            self.bottomLogoUrl =
              "https://api2.yg-u.net/images/post/" +
              self.FooterData[0]["postImage"];

            self.facebook = self.FooterData[0]["facebook1"];
            self.instgram = self.FooterData[0]["instgram1"];
            self.linkedIn = self.FooterData[0]["linkedIn1"];
            self.twitter = self.FooterData[0]["tweeter1"];
            self.youtube = self.FooterData[0]["youtube1"];
            self.postTitle = self.FooterData[0]["postTitle"];
            self.postSubTitle = self.FooterData[0]["postSubTitle"];
            self.f1 = self.FooterData[0]["f11"];
            self.f2 = self.FooterData[0]["f21"];
            self.f3 = self.FooterData[0]["f31"];
            self.f4 = self.FooterData[0]["f41"];
            self.f5 = self.FooterData[0]["f51"];
            self.f6 = self.FooterData[0]["f61"];
          }
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);
        })
        .catch(function (response) {
          console.log("error", response);
        });
    },
  },
  mounted() {
    window.scroll(0, 0);
    var self = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);

        self.getApis();
      });
    } else self.getApis();
  },
};
</script>

<style scoped></style>
